import { Box, keyframes, Link, styled, Typography } from "@mui/material";
import React from "react";
import { Counter } from "../features/counter/Counter";
import DarkModeButton from "./DarkModeButton";
import logo from "./logo.svg";

const AppLogoFloat = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
`;

const StyledHeader = styled("header")(({ theme }) => ({
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "calc(10px + 2vmin)",
}));

const StyledLogo = styled("img")(({ theme }) => ({
  height: "40vmin",
  pointerEvents: "none",

  "@media (prefers-reduced-motion: no-preference)": {
    animation: `${AppLogoFloat} infinite 3s ${theme.transitions.easing.easeInOut}`,
  },
}));

const StyledDarkModeButton = styled(DarkModeButton)(({ theme }) => ({
  position: "fixed",
  top: theme.spacing(1),
  right: theme.spacing(1),
}));

function App() {
  return (
    <Box textAlign="center">
      <StyledDarkModeButton />
      <StyledHeader>
        <StyledLogo src={logo} alt="logo" />
        <Counter />
        <Typography>
          Edit <code>src/app/App.tsx</code> and save to reload.
        </Typography>
        <Typography>
          <span>Learn </span>
          <Link
            className="App-link"
            href="https://reactjs.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            React
          </Link>
          <span>, </span>
          <Link
            className="App-link"
            href="https://redux.js.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Redux
          </Link>
          <span>, </span>
          <Link
            className="App-link"
            href="https://redux-toolkit.js.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Redux Toolkit
          </Link>
          ,<span> and </span>
          <Link
            className="App-link"
            href="https://react-redux.js.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            React Redux
          </Link>
        </Typography>
      </StyledHeader>
    </Box>
  );
}

export default App;
