import {
  Action,
  configureStore,
  MiddlewareArray,
  ThunkAction,
} from "@reduxjs/toolkit";
import { PropsWithChildren } from "react";
import { Provider } from "react-redux";
import { createReduxHistoryContext } from "redux-first-history";
import { LogEntryObject } from "redux-logger";
import { persistStore } from "redux-persist";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist/es/constants";
import { PersistGate } from "redux-persist/integration/react";
import browserHistory from "../util/history";
import counterReducer from "./counter/counterSlice";
import generalReducer from "./general/generalSlice";
import notificationReducer from "./notification/notificationSlice";

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: browserHistory,
  });

export const store = configureStore({
  reducer: {
    router: routerReducer,
    general: generalReducer,
    notification: notificationReducer,
    counter: counterReducer,
  },
  middleware: (getDefaultMiddleware) => {
    let middlewares = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        ignoredPaths: [],
      },
    }).concat(routerMiddleware);

    // Only log in dev mode
    if (process.env.NODE_ENV === "development") {
      const { createLogger } = require("redux-logger");
      const logger = createLogger({
        collapsed: (
          getState: () => any,
          action: any,
          logEntry?: LogEntryObject
        ) => !logEntry?.error,
      });

      (middlewares as MiddlewareArray<any>) = middlewares.concat(logger);
    }

    return middlewares;
  },
  devTools: process.env.NODE_ENV === "development",
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const persistor = persistStore(store);

export const history = createReduxHistory(store);

// Ensures persist gate and provider are mounted hand in hand and keeps other parts of code cleaner
export default function ReduxProvider(props: PropsWithChildren<unknown>) {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>{props.children}</PersistGate>
    </Provider>
  );
}
