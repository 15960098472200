import React from "react";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { HistoryRouter as Router } from "redux-first-history/rr6";
import App from "./app/App";
import Notifier from "./app/Notifier";
import ReduxProvider from "./features/store";
import reportWebVitals from "./reportWebVitals";
import Theme from "./theme/Theme";
import history from "./util/history";

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ReduxProvider>
      <Router history={history}>
        <HelmetProvider>
          <Theme>
            <Notifier />

            <App />
          </Theme>
        </HelmetProvider>
      </Router>
    </ReduxProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
