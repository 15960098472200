import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import DarkMode from "../../util/DarkMode";
import { RootState } from "../store";

interface GeneralState {
  isMobileNavOpen: boolean;
  darkMode: keyof typeof DarkMode;
}

const initialState: GeneralState = {
  isMobileNavOpen: false,
  darkMode: "detect",
};

export const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    toggleMobileNavOpen: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.isMobileNavOpen = action.payload ?? !state.isMobileNavOpen;
    },
    setDarkMode: (state, action: PayloadAction<keyof typeof DarkMode>) => {
      state.darkMode = action.payload;
    },
  },
});

export const { toggleMobileNavOpen, setDarkMode } = generalSlice.actions;

export const selectIsMobileNavOpen = (state: RootState) =>
  state.general.isMobileNavOpen;

export const selectDarkMode = (state: RootState) => state.general.darkMode;

const persistConfig = {
  key: generalSlice.name,
  storage,
  whitelist: ["darkMode"],
};

export default persistReducer(persistConfig, generalSlice.reducer);
