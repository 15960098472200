import { Add, Remove } from "@mui/icons-material";
import { Box, Button, IconButton, Input, styled } from "@mui/material";
import React, { useState } from "react";

import { useAppDispatch, useAppSelector } from "../hooks";
import {
  decrement,
  increment,
  incrementAsync,
  incrementByAmount,
  incrementIfOdd,
  selectCount,
} from "./counterSlice";

const StyledRow = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  "& > button": {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(1),
  },

  "&:not(:last-child)": {
    marginBottom: theme.spacing(2),
  },
}));

const StyledValue = styled("span")(({ theme }) => ({
  fontSize: "78px",
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  marginTop: 2,
  fontFamily: "'Courier New', Courier, monospace",
}));

export function Counter() {
  const count = useAppSelector(selectCount);
  const dispatch = useAppDispatch();
  const [incrementAmount, setIncrementAmount] = useState("2");

  const incrementValue = Number(incrementAmount) || 0;

  return (
    <Box>
      <StyledRow>
        <IconButton
          aria-label="Decrement value"
          color="error"
          onClick={() => dispatch(decrement())}
        >
          <Remove />
        </IconButton>
        <StyledValue>{count}</StyledValue>
        <IconButton
          aria-label="Increment value"
          color="success"
          onClick={() => dispatch(increment())}
        >
          <Add />
        </IconButton>
      </StyledRow>
      <StyledRow>
        <Input
          type="text"
          aria-label="Set increment amount"
          value={incrementAmount}
          onChange={(e) => setIncrementAmount(e.target.value)}
        />
        <Button onClick={() => dispatch(incrementByAmount(incrementValue))}>
          Add Amount
        </Button>
        <Button onClick={() => dispatch(incrementAsync(incrementValue))}>
          Add Async
        </Button>
        <Button onClick={() => dispatch(incrementIfOdd(incrementValue))}>
          Add If Odd
        </Button>
      </StyledRow>
    </Box>
  );
}
